var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-vertical',{scopedSlots:_vm._u([{key:"navbar",fn:function(ref){
var toggleVerticalMenuActive = ref.toggleVerticalMenuActive;
return [_c('div',{attrs:{"slot":"navbar"},slot:"navbar"},[_c('div',{staticClass:"header-navbar navbar navbar-shadow align-items-center"},[_c('div',{staticClass:"navbar-container d-flex content align-items-center"},[_c('ul',{staticClass:"nav navbar-nav d-xl-none"},[_c('li',{staticClass:"nav-item"},[_c('b-link',{staticClass:"nav-link"},[_c('feather-icon',{attrs:{"icon":"MenuIcon","size":"21"},on:{"click":toggleVerticalMenuActive}})],1)],1)]),(_vm.$auth.user && _vm.$auth.hasRole(['superadmin']))?_c('router-link',{staticClass:"mt-1 mt-sm-0 mb-1 mb-sm-0 m-sm-1",attrs:{"to":"/admin","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-button',{class:isActive ? 'active' : '',attrs:{"variant":"secondary","active":isActive},on:{"click":navigate}},[_vm._v(" Admin ")])]}}],null,true)}):_vm._e(),(_vm.$auth.db.impersonated_by)?_c('span',[_vm._v("Je bent ingelogd als "),_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$auth.db.fullname))])]):_vm._e()],1)])])]}},{key:"vertical-menu-header",fn:function(ref){
var isVerticalMenuActive = ref.isVerticalMenuActive;
var toggleVerticalMenuActive = ref.toggleVerticalMenuActive;
return [_c('div',{staticClass:"d-flex align-items-center h-100",attrs:{"slot":"vertical-menu-header","is-vertical-menu-active":isVerticalMenuActive,"toggle-vertical-menu-active":toggleVerticalMenuActive},slot:"vertical-menu-header"},[_c('h1',{staticClass:"font-medium-5 mb-0 text-primary"},[_c('img',{attrs:{"src":require("@/assets/images/logo/logo-dark.png"),"alt":"","width":"100%"}})])])]}}])},[_c('router-view'),(_vm.showCustomizer)?_c('app-customizer',{attrs:{"slot":"customizer"},slot:"customizer"}):_vm._e(),_c('div',{staticClass:"d-flex mb-0",attrs:{"slot":"footer"},slot:"footer"},[_c('p',{staticClass:"mb-0"},[_vm._v(" © Familiebank "+_vm._s(new Date().getFullYear())+" ")]),_c('p',{staticClass:"ml-2"},[_c('a',{attrs:{"href":"mailto:info@familiebank.nl"}},[_vm._v("info@familiebank.nl")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }