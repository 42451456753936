<template>
  <layout-vertical>

    <router-view />

    <!-- <div slot="navbar" class="px-1">
      <button :toggleVerticalMenuActive="toggleVerticalMenuActive">Test</button>
    </div> -->

    <!-- used to remove the default values -->
    <template v-slot:navbar="{ toggleVerticalMenuActive }">
      <div slot="navbar">
        <div class="header-navbar navbar navbar-shadow align-items-center">
          <!-- <app-navbar-vertical-layout :toggleVerticalMenuActive="toggleVerticalMenuActive" /> -->

          <div class="navbar-container d-flex content align-items-center">

            <ul class="nav navbar-nav d-xl-none">
              <li class="nav-item">
                <b-link class="nav-link">
                  <feather-icon icon="MenuIcon" size="21" @click="toggleVerticalMenuActive" />
                </b-link>
              </li>
            </ul>

            <router-link to="/admin" v-if="$auth.user && $auth.hasRole(['superadmin'])" custom v-slot="{ navigate, isActive }" class="mt-1 mt-sm-0 mb-1 mb-sm-0 m-sm-1">
              <b-button @click="navigate" variant="secondary" :active="isActive" :class="isActive ? 'active' : ''">
                Admin
              </b-button>
            </router-link>
            <span v-if="$auth.db.impersonated_by">Je bent ingelogd als <span class="font-weight-bold" style="color: red;">{{ $auth.db.fullname }}</span></span>
          </div>
        </div>
      </div>

    </template>

    <template v-slot:vertical-menu-header="{ isVerticalMenuActive, toggleVerticalMenuActive }">
      <div slot="vertical-menu-header" class="d-flex align-items-center h-100" :is-vertical-menu-active="isVerticalMenuActive" :toggle-vertical-menu-active="toggleVerticalMenuActive">
        <h1 class="font-medium-5 mb-0 text-primary">
          <img src="@/assets/images/logo/logo-dark.png" alt="" width="100%">
        </h1>
      </div>
    </template>

    <app-customizer v-if="showCustomizer" slot="customizer" />

    <div slot="footer" class="d-flex mb-0">
      <p class="mb-0">
        &copy; Familiebank {{ new Date().getFullYear() }}
      </p>
      <p class="ml-2"><a href="mailto:info@familiebank.nl">info@familiebank.nl</a></p>
    </div>

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
// import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'



export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    // AppNavbarVerticalLayout
    // BLink,

    // Navbar Components
    // BNavbarNav,
    // Bookmarks,
    // Locale,
    // SearchBar,
    // DarkToggler,
    // CartDropdown,
    // NotificationDropdown,
    // UserDropdown,
  },
  setup() {
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
